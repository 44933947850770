import {computed, watchEffect, ref, reactive, watch} from 'vue';

// Note: do not use getCurrentInstance, or other setup stuff here.
// This way, we can run outside of setup().
export default (inputList) => {
    let index = ref(0);
    
    let list = ref(inputList);
    
    let length = computed (() => {
        if ( ! Array.isArray(list.value)) {
            return 0;
        }
       
        return list.value.length;
    });
   
    let maxIndex = computed (() => {
        // noinspection JSIncompatibleTypesComparison
        if (length.value === 0) {
            return 0;
        }
        
        return length.value - 1;
    });
    
    let lastIndex = maxIndex;
    
    let currentItem = computed( () => {
        // noinspection JSIncompatibleTypesComparison
        if ( length.value === 0) {
            return null;
        }
 
        return list.value[currentIndex.value];
    });
    
    let hasNext      = computed(() => {
        return currentIndex.value < maxIndex.value;
    });
    
    let hasPrev      = computed(() => {
        return currentIndex.value > 0;
    });
    
    let currentIndex = computed(() => {
        return index.value;
    });
    
    let nextIndex = computed(() => {
        if (hasNext.value) {
            return currentIndex.value + 1;
        }
    
        return 0;
    
    });
    
    let prevIndex = computed(() => {
        if (currentIndex.value === 0) {
            return maxIndex.value;
        }
    
        return currentIndex.value - 1;
    });
    
    let setIndex = (targetIndex) => {
        if (targetIndex > maxIndex.value || targetIndex < 0) {
            debug('useListIterator.setIndex - index exceeds max index, or is negative', 1, targetIndex);
            index.value = 0;
        }
        
        index.value = targetIndex;
    };
    
    let goToNext = () => {
        setIndex(nextIndex.value);
    };
    
    let goToPrev = () => {
        setIndex(prevIndex.value);
    };
    
    let goToItem =  (targetIndex) => {
        return setIndex(targetIndex);
    };
    
    let getItemIndex = (target) => {
        if ( ! Array.isArray(list.value)) {
            return false;
        }
    
        let searchIndex = 0;
        let found = false;
    
        for (const item of list.value) {
            if (item === target) {
                found = true;
                break;
            }
            searchIndex++;
        }
        return found ? searchIndex : false;
    }
    
    // enforce index integrity
    watchEffect (() => {
            if (currentIndex.value > maxIndex.value) {
                index.value = maxIndex.value;
            }
            
            if ( currentIndex.value < 0) {
                index.value = 0;
            }
        },
    );
    
    
    let listIterator = {
        length, maxIndex, lastIndex, currentItem, hasNext, hasPrev,
        nextIndex, prevIndex,
        currentIndex, setIndex,goToNext, goToPrev,
        goToItem, getItemIndex
    }
    return {
        listIterator,
        ...listIterator
    }
    
    
    
}
